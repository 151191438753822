import { defineStore } from "pinia";
import mushroom from "cem-primary-api";
import MethodService from "../service/MethodService";

export const CustomerStore = defineStore({
  id: "customerStore",
  state: () => ({
    listFieldName: [],
    listColName: [],
    listType: [],
    listService: [],
  }),

  actions: {
    async getCustomerSummary() {
      this.listType = [];
      this.listFieldName = [];
      this.listColName = [];
      try {
        const response = await mushroom.customer_summary.getAllAsync(
          { limit: 100 },
          {
            enabledCache: false,
          }
        );
        if (response.result) {
          this.listFieldName.push(
            ...new Set(response.result.map((x) => x.field_name))
          );

          this.listColName.push(
            ...new Set(response.result.map((x) => x.col_name))
          );

          this.listType.push(...new Set(response.result.map((x) => x.type)));
        }
      } catch (e) {
        MethodService.showError(e.code);
      }
    },

    async getService() {
      this.listService = [];
      try {
        const response = await mushroom.service.getAllAsync(
          { limit: 100 },
          {
            enabledCache: false,
          }
        );

        if (response.result?.length > 0) {
          this.listService.push(
            ...new Set(
              response.result.map((x) => {
                return {
                  id: x.id,
                  name: x.name,
                };
              })
            )
          );
        }
      } catch (e) {
        MethodService.showError(e.code);
      }
    },
  },
  getters: {
    showListService(state) {
      return state.listService;
    },
  },
});
